import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Container } from "@chakra-ui/react";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Imprint | THE PROJECT STATUS" urlPage="/imprint" mdxType="SEO" />
    <Container maxW="800px" minH="640px" px={4} mdxType="Container">
      <h1 {...{
        "id": "imprint",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#imprint",
          "aria-label": "imprint permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Imprint`}</h1>
      <h2 {...{
        "id": "credits-and-thanks",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#credits-and-thanks",
          "aria-label": "credits and thanks permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Credits and thanks`}</h2>
      <ul>
        <li parentName="ul">{`Icons by `}<a parentName="li" {...{
            "href": "https://creativemarket.com/Becris",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Bekris`}</a></li>
      </ul>
      <h2 {...{
        "id": "responsibility",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#responsibility",
          "aria-label": "responsibility permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Responsibility`}</h2>
      <p>{`The sole responsibility for this webapp with respect to all legal or compliance
regulations lies purely with Prof. Dr. Ulrich Anders.`}</p>
      <p>{`Prof. Dr. Ulrich Anders`}<br />{` CBS International Business School `}<br />{`
Hardefuststr. 1`}<br />{` D-50677 Cologne `}<br />{` prof.anders@online.de`}</p>
      <p>{`The use of this website is purely voluntarily and at the users own risk. This
website uses complex tree algorithms that have been seriously tested. However,
it is the responsibility of the user to double check or quality assure the
result produced by the algorithms.`}</p>
      <p>{`Neither Prof. Anders nor CBS International Business School can in any way be
held accountable for any abuse, misconduct, erroneous content, wrong or
unintended use of this website.`}</p>
      <h2 {...{
        "id": "license",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#license",
          "aria-label": "license permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`License`}</h2>
      <p>{`The authors of this webapp Prof. Dr. Ulrich Anders and Jasper Anders grant a
free License `}<a parentName="p" {...{
          "href": "https://creativecommons.org/licenses/by-nd/4.0/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`CC BY-ND 4.0`}</a>{` to
all its users. For all enquires beyond this license please send an email to
prof.anders@online.de. Thank you.`}</p>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      